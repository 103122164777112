import type { JSX } from "@emotion/react/jsx-runtime";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import type { FloatingLabelInputProps } from "@aviary";
import { FloatingLabelInput } from "@aviary";
import { l } from "@shared/locales/i18n";

import { isLengthValid, isEmailAtValid } from "./EmailValidation/EmailValidation";
import { SuggestedEmail } from "./SuggestedEmail/SuggestedEmail";
import { useSuggestedEmail } from "./useSuggestedEmail";

interface Props extends Omit<FloatingLabelInputProps, "id" | "label" | "type" | "errors"> {
  error?: string | JSX.Element;
  descriptiveText?: ReactNode;
  isLoading?: boolean;
  isOptional?: boolean;
  isDirty: boolean;
  hideSuggestion?: boolean;
  label?: string;
  value: string;
  setEmail: (value: string) => void;
  onSuggestedEmailClick?: (suggestedEmail: string) => void;
}

const EmailInput = ({
  wrapperStyles,
  disabled,
  isLoading,
  descriptiveText,
  isDirty,
  hideSuggestion = false,
  error,
  setEmail,
  onSuggestedEmailClick,
  label = l.sharedCommon.Email,
  value,
  isOptional,
  ...rest
}: Props) => {
  const { t } = useTranslation();
  const { suggestedEmail, onEmailChange, clearSuggestedEmail } = useSuggestedEmail();

  const handleSuggestedEmailClick = () => {
    setEmail(suggestedEmail);
    clearSuggestedEmail();
    onSuggestedEmailClick?.(suggestedEmail);
  };

  const renderSuggestedEmail = () => {
    if (!suggestedEmail || hideSuggestion) return null;

    return (
      <SuggestedEmail
        suggestedEmail={suggestedEmail}
        handleSuggestedEmailClick={handleSuggestedEmailClick}
      />
    );
  };

  const getError = () => {
    if (isOptional && !isLengthValid(value)) return null;
    if (!isLengthValid(value)) {
      return t(l.sharedCommon.EmailRequired);
    }
    if (!isEmailAtValid(value)) {
      return t(l.sharedCommon.EmailMustHaveAt);
    }
    if (error) return error;

    return renderSuggestedEmail();
  };

  const getErrors = () => {
    if (!isDirty) return [renderSuggestedEmail()];

    return [getError()];
  };

  const onChange: FloatingLabelInputProps["onChange"] = e => {
    rest.onChange?.(e);
    onEmailChange(e.target.value);
  };

  return (
    <FloatingLabelInput
      maxLength={255}
      value={value}
      descriptiveText={descriptiveText}
      id="email"
      data-e2e="email"
      type="email"
      name="email"
      label={t(label)}
      wrapperStyles={wrapperStyles}
      errors={getErrors()}
      disabled={disabled || isLoading}
      {...rest}
      onChange={onChange}
    />
  );
};
export { EmailInput };
