import { css, type Theme } from "@emotion/react";
import { borders } from "aviary-tokens";

import { animations, dimensions, timing, layers, utilities } from "@styles";
import { autoFillDetector } from "@styles/helpers";

export const controlBase = (theme: Theme) => css`
  transition: all ${timing.moderate} ${animations.easeOutCubic};
  color: ${theme.system.textBase};
  align-items: center;
  display: inline-flex;
  font-size: 1rem;
  height: auto;
  justify-content: flex-start;
  line-height: 1.5;
  position: relative;
  vertical-align: top;
  width: 100%;
  background-color: ${theme.system.backgroundBase};

  box-sizing: border-box;
  clear: both;
  text-align: left;

  /* States */
  &:focus,
  &:active {
    outline: none;
  }

  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
    background-color: ${theme.system.backgroundMutedHover};
    color: ${theme.disabled.text};
    border-color: ${theme.disabled.border};
  }
`;

export const requiredAsterisk = (theme: Theme) => css`
  color: ${theme.danger.textBase};
`;

export const floatingLabelInputErrorWrapper = css`
  width: 100%;
`;

export const floatingLabelWrapper = (theme: Theme) => css`
  ${controlBase(theme)};
  z-index: ${layers.indexAccessibleBottom};
  border-radius: ${borders.radiusBase};
`;

export const passwordToggle = {
  base: css`
    position: absolute;
    right: 0;
    top: calc(50% + 0.5rem);
    transform: translate(-50%, -50%);
    padding: 0.325rem;
    z-index: ${layers.zIndexLowForeground};

    @media (min-width: ${dimensions.tablet}) {
      cursor: pointer;
    }
  `,

  hideLabel: css`
    top: calc(50%);
  `,

  disabled: css`
    display: none;
  `,
};

export const htmlInput = {
  base: (theme: Theme) => css`
    ${controlBase(theme)};
    ${autoFillDetector};
    z-index: 2;
    top: 0;
    appearance: none;
    border: 1px solid ${theme.system.borderBase};
    border-radius: ${borders.radiusBase};
    box-shadow: none;
    padding: 1rem;
    padding-right: 2.5rem;

    &:hover {
      border-color: ${theme.primary.borderHover};
    }

    &:focus,
    &:active {
      border-color: ${theme.primary.borderActive};
      color: ${theme.system.textActive};
    }

    &[disabled],
    fieldset[disabled] & {
      background-color: ${theme.system.backgroundMutedHover};
      color: ${theme.disabled.text};
    }
  `,
  filledWithLabel: css`
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  `,
  hasIcon: css`
    padding-right: 2.5rem;
  `,
  errorStyles: (theme: Theme) => css`
    border-color: ${theme.danger.borderBase};
  `,
};

export const label = {
  base: (theme: Theme) => css`
    transition:
      transform 0.1s cubic-bezier(0.1, 0.55, 0.83, 0.67),
      color 0.3s ease-out;
    font-size: 1rem;
    position: absolute;
    transform-origin: top left;
    transform: translate(16px, 0) scale(1);
    color: ${theme.system.textBase};
    cursor: text;
    z-index: 3;
  `,

  focused: (theme: Theme) => css`
    color: ${theme.system.textActive};
    font-weight: ${theme.aviaryTypography.h5.fontWeight};
    transform: translate(16px, -12px) scale(0.85);
  `,

  hidden: css`
    ${utilities.visuallyHidden};
  `,
  filled: css`
    transform: translate(16px, -12px) scale(0.85);
  `,
  disabled: (theme: Theme) => css`
    color: ${theme.disabled.text};
    cursor: not-allowed;
  `,
  hasIcon: css`
    padding-right: 2.5rem;
  `,
  errorStyles: (theme: Theme) => css`
    color: ${theme.danger.textBase};
  `,
};

export const icon = (theme: Theme) => css`
  color: ${theme.text.emphasis};
`;
