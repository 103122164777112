import { css, type Theme } from "@emotion/react";

import { animations, dimensions, timing, layers, utilities } from "@styles";
import { autoFillDetector } from "@styles/helpers";

export const controlBase = (theme: Theme) => css`
  transition: all ${timing.moderate} ${animations.easeOutCubic};
  color: ${theme.input.textBase};
  align-items: center;
  display: inline-flex;
  font-size: 1rem;
  height: auto;
  justify-content: flex-start;
  line-height: 1.5;
  position: relative;
  vertical-align: top;
  width: 100%;
  background-color: ${theme.input.backgroundBase};

  box-sizing: border-box;
  clear: both;
  text-align: left;

  &:hover {
    background-color: ${theme.input.backgroundHover};
  }

  /* States */
  &:focus,
  &:active {
    outline: none;
  }

  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
  }
`;

export const requiredAsterisk = (theme: Theme) => css`
  color: ${theme.input.textFloatingLabelError};
`;

export const floatingLabelInputErrorWrapper = css`
  width: 100%;
`;

export const floatingLabelWrapper = (theme: Theme) => css`
  ${controlBase(theme)};
  z-index: ${layers.indexAccessibleBottom};
  border-radius: ${dimensions.borderRadius} ${dimensions.borderRadius} 0 0;
`;

export const passwordToggle = {
  base: css`
    position: absolute;
    right: 0;
    top: calc(50% + 0.5rem);
    transform: translate(-50%, -50%);
    padding: 0.325rem;
    z-index: ${layers.zIndexLowForeground};

    @media (min-width: ${dimensions.tablet}) {
      cursor: pointer;
    }
  `,

  hideLabel: css`
    top: calc(50%);
  `,

  disabled: css`
    display: none;
  `,
};

export const htmlInput = {
  base: (theme: Theme) => css`
    ${controlBase(theme)};
    ${autoFillDetector};
    z-index: 2;
    top: 0;
    appearance: none;
    border: 1px solid ${theme.input.borderBase};
    border-bottom: 1px solid ${theme.input.borderEmphasized};
    border-radius: ${dimensions.borderRadius} ${dimensions.borderRadius} 0 0;
    box-shadow: none;
    padding: 1rem;
    padding-right: 2.5rem;

    /* IE Fix */
    &::-ms-clear {
      display: none;
    }

    &:focus {
      border-bottom-color: ${theme.input.borderActive};
    }

    &[disabled],
    fieldset[disabled] & {
      background-color: ${theme.disabled.inputBackground};
      color: ${theme.disabled.inputLabelText};
    }
  `,

  filledWithLabel: css`
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  `,
  hasIcon: css`
    padding-right: 2.5rem;
  `,
  errorStyles: (theme: Theme) => css`
    border-color: ${theme.input.borderError};
    &:focus {
      border-bottom-color: ${theme.input.borderError};
    }
  `,
  hasWhiteBackground: (theme: Theme) => css`
    background-color: ${theme.input.backgroundInformation};
  `,
};

export const label = {
  base: (theme: Theme) => css`
    transition:
      transform 0.1s cubic-bezier(0.1, 0.55, 0.83, 0.67),
      color 0.3s ease-out;
    font-size: 1rem;
    position: absolute;
    transform-origin: top left;
    transform: translate(16px, 0) scale(1);
    color: ${theme.input.textLabel};
    cursor: text;
    z-index: 3;
  `,

  focused: (theme: Theme) => css`
    color: ${theme.input.textFloatingLabel};
    transform: translate(16px, -12px) scale(0.85);
  `,

  hidden: css`
    ${utilities.visuallyHidden};
  `,
  filled: css`
    transform: translate(16px, -12px) scale(0.85);
  `,
  disabled: (theme: Theme) => css`
    color: ${theme.disabled.inputLabelText};
    cursor: not-allowed;
  `,
  hasIcon: css`
    padding-right: 2.5rem;
  `,
  errorStyles: (theme: Theme) => css`
    color: ${theme.input.textInformationError};
  `,
};
