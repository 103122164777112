import { useState } from "react";

import { useDebounce } from "@shared/hooks/useDebounce/useDebounce";

import { emailFuzzyMatch } from "./EmailFuzzyMatch/emailFuzzyMatch";

const useSuggestedEmail = () => {
  const [suggestedEmail, setSuggestedEmail] = useState("");

  const handleSuggestedEmail = (value: string) => {
    const email = emailFuzzyMatch(value);
    setSuggestedEmail(email);
  };

  const onEmailChange = useDebounce(handleSuggestedEmail, 500);

  const clearSuggestedEmail = () => {
    setSuggestedEmail("");
    onEmailChange.cancel();
  };

  return { suggestedEmail, onEmailChange, clearSuggestedEmail };
};

export { useSuggestedEmail };
